<template>
  <v-container class="fill-height">
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-col
        v-if="loading"
        class="text-subtitle-1 text-center"
        cols="12"
      >
        <v-icon large>
          mdi-cog-sync
        </v-icon>
        Creating your file
      </v-col>
      <v-col
        v-if="success"
        class="text-subtitle-1 text-center"
        cols="12"
      >
        <v-icon large>
          mdi-cloud-download
        </v-icon>
        File succesfully generated, check the download section of your browser
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          v-if="loading"
          color="primary"
          indeterminate
          rounded
          height="6"
        />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="errorSnackbar"
      color="error"
      bottom
      center
    >
      Sorry, an error occured retrieving your file
    </v-snackbar>
  </v-container>
</template>

<script>
  import { API } from 'aws-amplify'
  import { utils, writeFileXLSX } from 'xlsx'

  export default {
    data () {
      return {
        loading: true,
        success: false,
        errorSnackbar: false,
      }
    },

    created: function () {
      this.loadProductListAndExportToExcel()
    },

    methods: {
      async loadProductListAndExportToExcel () {
        const apiName = 'listeProdottiApi'
        const path = '/listeProdotti'

        API
          .get(apiName, path + `/${this.$route.params.list_id}/${this.$route.params.list_key}`)
          .then(response => {
            this.loading = false
            const excelData = [
              [
                '---------------',
              ],
              [
                'Contact',
              ],
              [
                '---------------',
              ],
              [
                'Business Name',
                response[0].businessName,
              ],
              [
                'Brand',
                response[0].brand,
              ],
              [
                'Industry',
                response[0].industry,
              ],
              [
                'Name',
                response[0].name,
              ],
              [
                'Phone',
                response[0].phone,
              ],
              [
                'Email',
                response[0].email,
              ],
              [
                'Web',
                response[0].web,
              ],
              [
                'Role',
                response[0].role,
              ],
              [
                '---------------',
              ],
              [
                'Products List',
              ],
              [
                '---------------',
              ],
              [
                'Code',
                'Description',
                'Variant / Color',
                'Link to Product Detail',
              ],
            ]
            const firstProductLine = excelData.length + 1
            JSON.parse(response[0].products).forEach(product => {
              excelData.push([
                product.code,
                product.description,
                product.variant ? (product.variant.description + '(' + product.variant.code + ')') : '-',
                product.domain + product.url,
              ])
            })
            const ws = utils.aoa_to_sheet(excelData)
            const wb = utils.book_new()
            utils.book_append_sheet(wb, ws)
            for (let index = firstProductLine; index <= excelData.length; index++) {
              ws['D' + index].l = { Target: ws['D' + index].v }
            }
            writeFileXLSX(wb, 'B2BLorenziProductsList.xlsx')
            this.success = true
          })
          .catch(error => {
            /* eslint no-console: 0 */
            console.error(error.response)
            this.loading = false
            this.errorSnackbar = true
          })
      },
    },
  }
</script>
